import React from 'react';
type Item = {
  value: string;
  name: string;
  checked?: boolean;
  length?: number;
};

type FilterProps<T> = {
  type: string;
  title?: string;
  options: Array<T>;
  initial: string;
  onchange: (value: any) => void;
};

const Filter:React.FC<FilterProps<Item>> = ({ type, title, options, initial, onchange }: FilterProps<Item>) =>  {
  let name: any;

  if (type === 'radio') {
    name = {
      name: title,
    };
    for (const i in options) {
      if (options[i].value === initial) {
        options[i].checked = true;
      }
    }
  } else {
    if (initial)
      for (const i in options) {
        if (initial.includes(options[i].value)) {
          options[i].checked = true;
        }
      }
  }

  return (
    <div className='filter'>
      {/* <h3>{title}</h3> */}
      {options.map((option: Item) => (
        <div key={option.value} className='ck_main'>
          <input
            className='form-check-input'
            type={type}
            id={option.value}
            checked={option.checked ?? false}
            {...name}
            onChange={e => onchange(e.target.id)}
          />
          <label htmlFor={option.value}>
            <span>{option.name}</span>
            <span>{Object.prototype.hasOwnProperty.call(option, 'length') ? option.length : '453'}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default Filter;
