import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  userDetails: [],
  status: null,
  error: null
};

export const fetchUserDetails:any = createAsyncThunk('userDetails/fetchUserDetails', async () => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/getUserDetails`,
      method: 'POST',
      data: {},
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    // const state = getState();
    console.error('Error fetching user details:', error);
    // console.error('Current state:', state);
    throw error;
  }
});

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserDetails.pending]: (state: any, action: any) => {
      state.status = 'pending';
    },
    [fetchUserDetails.fulfilled]: (state: any, action: any) => {
      state.status = 'success';
      state.data = action.payload;
    },
    [fetchUserDetails.rejected]: (state: any, action: any) => {
      state.status = 'rejected';
    },
  },
});

// export const selectUserDetailsState = (state:any) => state.userDetails;

export default userDetailsSlice.reducer;
