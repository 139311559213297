import React from 'react';
import { getUserWisePluginInterface } from '../Interface/appInterface';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const UserPackagesCard:React.FC<getUserWisePluginInterface> = (props: getUserWisePluginInterface) => {
  return (
    <>
      <tr key={props.item.id}>
        <td>
          <div className='d-flex'>
            <div className='align-items-center plg_area mx-2'>
              <h6 className='text-white'>{props.item.name}</h6>
              {props.item.type === 'private' ? (
                <button type='button' className='orange_btn my-2'>
                  Make public
                </button>
              ) : (
                <button type='button' className='cblue_btn my-2'>
                  View in Store
                </button>
              )}
            </div>
            <div className='ms-2'>
              {props.item.type === 'private' ? (
                <>
                  <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#dc8515' }} /> {props.item.type}
                </>
              ) : null}
            </div>
          </div>
        </td>
        <td>
          <div>
            <p className='text-white'>{moment(props.item.created_at).format('DD-MM-YYYY')}</p>
          </div>
        </td>
        <td>
          <div>
            <p className='text-white'>V {props.item.version}</p>
          </div>
        </td>
        <td>
          <div>
            <p className='text-white'>{props.item.wp_author}</p>
          </div>
        </td>
        <td>
          <div>
            <p className='text-white'>{props.item.downloaded}</p>
          </div>
        </td>
        <td>
          <div>
            <button type='button' className='cblue_btn my-2'>
              Upload New version
            </button>
            <br />
            {props.item.type === 'private' ? (
              <button type='button' className='red_btn my-2'>
                Delete
              </button>
            ) : (
              <button type='button' className='orange_btn my-2'>
                Unpublish
              </button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}

export default UserPackagesCard;
