import React from 'react';
import { useAppSelector, useAppDispatch } from '../features/hooks';

import { toggleRatings } from '../features/storeFilters';

import Filter from '../components/Filter';

export interface RatingInterface {
  ratingData: any;
}

const Ratings:React.FC<RatingInterface> = (props: RatingInterface) =>  {
  // const ratingsOpts = [
  //   {
  //     value: "5",
  //     name: "4 star and higher",
  //   },
  //   {
  //     value: "4",
  //     name: "3 star and higher",
  //   },
  //   {
  //     value: "3",
  //     name: "2 star and higher",
  //   },
  //   {
  //     value: "2",
  //     name: "1 star and higher",
  //   },
  //   {
  //     value: "1",
  //     name: "Show all",
  //   },
  // ];

  const ratingsOpts =
    typeof props.ratingData === 'object' && props.ratingData !== null
      ? Object.entries(props.ratingData)
          .map(([key, value]: any) => {
            return {
              value: key,
              name: `${key} stars and higher`,
              length: value.length,
            };
          })
          .sort((a, b) => parseInt(b.value) - parseInt(a.value))
      : [];
  console.log('ratingsOpts', ratingsOpts, props.ratingData);

  const ratings = useAppSelector(state => state.storeFilters.value.data.ratings);
  const dispatch = useAppDispatch();

  return (
    <Filter
      type='checkbox'
      title='Ratings'
      options={ratingsOpts}
      initial={ratings.length > 0 ? ratings[1] : '1'}
      onchange={(value: string) => dispatch(toggleRatings(value))}
    />
  );
}
export default Ratings;
