/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import axios from 'axios';
import { Buffer } from 'buffer';
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCheckWp = async (siteUrl: string, username: string, app_password: string) => {
  try {
    const credentials = `${username}:${app_password}`;
    const encodedCredentials = Buffer.from(credentials).toString('base64');
    const headers = { Authorization: `Basic ${encodedCredentials}` };
    const Plugins = await axios.get(`${siteUrl}/wp-json/wp/v2/plugins`, { headers });
    return true;
  } catch (error) {
    return false;
  }
};
export const useWpData = async (siteUrl: string, username: string, app_password: string) => {
  try {
    const credentials = `${username}:${app_password}`;
    const encodedCredentials = Buffer.from(credentials).toString('base64');
    const headers = { Authorization: `Basic ${encodedCredentials}` };
    const Plugins = await axios.get(`${siteUrl}/wp-json/wp/v2/plugins`, { headers });
    return Plugins;
  } catch (error) {
    return {};
  }
};

export const useChecksshCredentials = async (data: any) => {
  try {
    const test = await axios.post(`${process.env.REACT_APP_AUTH_NODE_URL}/api/ssh/ssh-credentials-check`, { data });
    console.log('test', test);
    return true;
  } catch (error) {
    return false;
  }
};

export const useCheckftpCredentials = async (data: any) => {
  try {
    const test = await axios.post(`${process.env.REACT_APP_AUTH_NODE_URL}/api/ssh/ftp-credentials-check`, { data });
    console.log('test', test);
    return true;
  } catch (error) {
    return false;
  }
};
