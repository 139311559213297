import { createSlice } from '@reduxjs/toolkit';
import { createSearchParams } from 'react-router-dom';

function setUrlSearch(filters: any) {
  const params =
    '?' +
    createSearchParams({
      type: filters.type,
      sh: filters.search,
      ...filters.data,
    });
  window.history.pushState(params, '', params);
}

function getState() {
  const search = new URLSearchParams(decodeURIComponent(window.location.search));
  return {
    value: {
      type: search.get('type') ?? 'plugins',
      search: search.get('sh') ?? '',
      data: {
        sort: search.get('sort') ?? 'popularity',
        perpage: search.get('perpage') ?? 20,
        ratings: search.getAll('ratings') ?? [],
        pg: search.get('pg') ?? 1,
        tag: search.get('tag') ?? '',
        vendor: search.get('vendor') ?? '',
        rating: search.getAll('rating') ?? [],
        order: search.get('order') ?? 'asc',
        datatype: search.get('datatype') ?? 'public',
        project_id: search.get('project_id') ?? '',
        env_id: search.get('env_id') ?? '',
        redirect_to: search.get('redirect_to') ?? '',
        flag: search.get('flag') ?? "plugins"
      },
    },
  };
}

export const storeFiltersSlice = createSlice({
  name: 'storeFilters',
  initialState: getState(),
  reducers: {
    calculate: state => {
      state.value = getState().value;
    },
    setType: (state, action) => {
      state.value.type = action.payload;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setSearch: (state, action) => {
      state.value.search = action.payload;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    
    },
    setSort: (state, action) => {
      state.value.data.sort = action.payload;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setPage: (state, action) => {
      state.value.data.pg = action.payload;
      setUrlSearch(state.value);
    },
    setPerpage: (state, action) => {
      state.value.data.perpage = action.payload;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setRatings: (state, action) => {
      state.value.data.ratings = action.payload;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setOrder: (state, action) => {
      state.value.data.order = action.payload;
      setUrlSearch(state.value);
    },
    setDataType: (state, action) => {
      state.value.data.datatype = action.payload;
      setUrlSearch(state.value);
    },
    setProjectType: (state, action) => {
      state.value.data.project_id = action.payload.project;
      state.value.data.env_id = action.payload.env;
      state.value.data.redirect_to = action.payload.redirect;
      setUrlSearch(state.value);
    },

    toggleRatings: (state, action) => {
      // let newArr = state.value.data.ratings.filter((v) => {
      //   return v !== action.payload;
      // });
      let newArr = [String(action.payload - 1), action.payload];

      // if (newArr.length === state.value.data.ratings.length) {
      //   newArr.push(action.payload);
      // }

      state.value.data.ratings = newArr;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setTag: (state, action) => {
      state.value.data.tag = action.payload;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setVendor: (state, action) => {
      state.value.data.vendor = action.payload;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setMaxPrice: (state, action) => {
      let priceRange = state.value.data.rating;
      priceRange[1] = action.payload;
      state.value.data.rating = priceRange;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setMinPrice: (state, action) => {
      let priceRange = state.value.data.rating;
      priceRange[0] = action.payload;
      state.value.data.rating = priceRange;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    setFlag:(state, action) => {
      state.value.data.flag=action.payload;
      state.value.data.pg = 1;
      setUrlSearch(state.value);
    },
    clearSearch: (state) => {
      state.value.search = '';
    },
  },
});

export const {
  calculate,
  setType,
  setSearch,
  setSort,
  setPage,
  setPerpage,
  setRatings,
  setOrder,
  setDataType,
  setProjectType,
  toggleRatings,
  setTag,
  setVendor,
  setMaxPrice,
  setMinPrice,
  setFlag,
  clearSearch,
} = storeFiltersSlice.actions;

export default storeFiltersSlice.reducer;
