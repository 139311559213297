import React, { useState } from 'react';

type InputProps = {
  min: number;
  placeholder?: string;
  initial: string;
  onChange: (value: string) => void;
};

const Input:React.FC<InputProps> = ({ min = 3, placeholder = 'Search...', initial = '', onChange }: InputProps) =>  {
  const [value, setValue] = useState(initial);

  const handleOnChange = (text: string) => {
    setValue(text);
    if (text.length >= min || text.length === 0) {
      onChange(text);
    }
  };

  return <input type='text' placeholder={placeholder} value={value} onChange={e => handleOnChange(e.target.value)} />;
}

export default Input;
