/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useEffect, useState} from 'react';
import { useAppSelector, useAppDispatch } from '../features/hooks';
import { useLocation } from 'react-router-dom';
import Input from '../components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { calculate, setFlag, setSearch } from '../features/storeFilters';

const Search:React.FC = () => {
  const search = useAppSelector(state => state.storeFilters.value.search);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchFunc, setSearchFunc] = useState<string>("")

  useEffect(() => {
    dispatch(calculate())
    // dispatch(setSearch(''));
  }, []);

  function addTextSearch (value:string):void {
    setSearchFunc(value)
    dispatch(setSearch(value))
  }


  return (
    <div className='search px-3 my-4'>
      <div className='search_main'>
        <Input min={3} initial={searchFunc} onChange={(value: string) => addTextSearch(value)} />
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </div>
  );
}
export default Search;
