import React, { SetStateAction, useEffect, useState } from 'react'
import img from '../img/store-thumbnail.svg';
import { Link } from 'react-router-dom';

interface WishlistItemInterface {
    plugin:any;
    wishlist:Array<any>;
    setWishlist:React.Dispatch<SetStateAction<any>>;
    isSelectedActive:boolean;
    selectPluginData:Array<any>;
    setSelectPluginData:React.Dispatch<SetStateAction<Array<any>>>;

}

const WishlistItem:React.FC<WishlistItemInterface> = ({plugin,wishlist,setWishlist,isSelectedActive,selectPluginData,setSelectPluginData}) =>  {
    const [src,setSrc] =useState(`https://ps.w.org/${plugin.slug}/assets/icon-256x256.png`);

    const handleRemove = (id:any) => {
        const updatedWishlist = wishlist.filter((item:any) => item.id !== id);
        localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
        setWishlist(updatedWishlist);
      };
      // const storePlugins = localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
      // const [selectPluginData, setSelectPluginData] = useState<Array<any>>(storePlugins);

const handleImageError = () => {
    setSrc(img)
 }

 const onAddPlugin = (plugin: any, event: any): void => {
  console.log('plugin', plugin, event, selectPluginData);
  const a = selectPluginData;
  const filterdata = selectPluginData.find((i: any) => {
    return i.id === plugin.id;
  });
  if (filterdata !== undefined) {
    const ab = a.filter((i: any) => {
      return i.id !== plugin.id;
    });
    localStorage.setItem('storequeue', JSON.stringify(ab!));
    setSelectPluginData(ab);
  } else {
    const abc = [...a, plugin];
    localStorage.setItem('storequeue', JSON.stringify(abc!));
    setSelectPluginData(abc);
  }
};
console.log("selectPluginData",selectPluginData)

  return (
    <div key={plugin.id} className='col-md-6 col-xl-4 col-sm-12 plugin_columns'>
    <div className='plug_grid'>
      <div className='card-body'>
        <div className='plugin_area'>
          <div className='plug_img'>
          {/* <img
    style={{ width: '100%', height: '256px', objectPosition: 'center center' }}
    className='store-item-image img-fluid'
    src={item.plugin.slug
      ? `https://ps.w.org/${item.plugin.slug}/assets/icon-256x256.png`
      : ''
    }
    alt={item.plugin.name}
    /> */}
      <img className='store-item-image img-fluid' style={{height:"256px", width:"100%" ,objectPosition:'center'}} src={src} onError={() => handleImageError()} alt=''></img>
          </div>
          <div className='list'>
          {/* <a style={{ textDecoration: 'none' }} href={`/plugins/${item.plugin.name}`}> */}
            <Link to={`/plugins/${plugin.slug}`} style={{ textDecoration: 'none' }}>
              <div className='pluginname font20'>{plugin.name}</div>
              </Link>
          {/* </a> */}

            <div className='author font16'>By:{plugin.wp_author}</div>
            <div className='up_date font16'>
              <span className='text-white font16'>Last updated : </span>
              {plugin.updated_at}
            </div>
            <div className='pricesection'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div aria-disabled='true'>
                    <span className='style-module_starRatingWrap__q-lJC' style={{ direction: 'ltr' }}>
                      <span className='style-module_simpleStarRating__nWUxf float-start' aria-hidden='true'>
                        <span
                          className='style-module_emptyIcons__Bg-FZ empty-icons'
                          style={{ color: 'rgb(136, 136, 136)' }}
                        >
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                        </span>
                        <span
                          className='style-module_fillIcons__6---A filled-icons'
                          title='4.7 out of 5'
                          style={{ left: '0px', color: 'rgb(242, 138, 3)', width: '94%' }}
                        >
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                          <svg
                            className='star-svg'
                            stroke='currentColor'
                            fill='currentColor'
                            stroke-width='0'
                            viewBox='0 0 24 24'
                            width='20'
                            height='20'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                          </svg>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div className='col-12 col-md-6 align-self-end'>
                  <div className='plugin_select_btn'>
                    <button className='cblue_btn ms-auto' type='button' style={{ zIndex: '2147483647' }}  onClick={() => handleRemove(plugin.id)}>
                      Remove
                    </button>
                  <button className='orange_btn ms-auto' type='button' style={{ zIndex: '2147483647' }}  onClick={e => onAddPlugin(plugin, e)}>
                      {isSelectedActive === true ? "Remove from queue" : "Add to queue"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default WishlistItem
