import React, { useState, useEffect } from 'react';
import WishlistItem from './WishlistItem';

const Wishlist:React.FC = () => {

  const [wishlist, setWishlist] = useState([]);
  const storePlugins = localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
      const [selectPluginData, setSelectPluginData] = useState<Array<any>>(storePlugins);

  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist');
    const fetchedWishlist = wishlistString ? JSON.parse(wishlistString) : [];
    setWishlist(fetchedWishlist);
  }, []);
  console.log('wishlist', wishlist);

  return (
    <main className='main' style={{padding:'60px', marginTop:'60px'}}>
      <h1 className='text-white font20' style={{marginBottom:'30px'}}>Wishlist</h1>
      <div className='container_fluid'>
        <div className='row plugin_row'>
          {wishlist.map((item: any) => {
            const isSelectedActive =
            selectPluginData.find((ik: any) => {
              return ik.id === item.id;
            }) !== undefined
              ? true
              : false;
            return(
              <WishlistItem plugin={item} wishlist={wishlist} setWishlist={setWishlist} isSelectedActive={isSelectedActive} selectPluginData={selectPluginData} setSelectPluginData={setSelectPluginData}  />
            )
            

          })}
        </div>
      </div>
    </main>
  );
};

export default Wishlist;
