/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppDispatch, useAppSelector } from '../features/hooks';

import { setType, setTag } from '../features/storeFilters';

import Filter from '../components/Filter';

export interface TypeInterface {
  typeData: any;
}

const Type:React.FC<TypeInterface> = (props: TypeInterface) => {
  // const typeOpts = [
  //   {
  //     value: 'themes',
  //     name: 'Themes',
  //   },
  //   {
  //     value: 'plugins',
  //     name: 'Plugins',
  //   },
  // ];

  const typeOpts =
    typeof props.typeData === 'object' && props.typeData !== null
      ? props.typeData
          .map((value: any, index: number) => {
            return {
              value: value.id,
              name: value.tag,
              length: value.count,
            };
          })
          .sort((a: any, b: any) => parseInt(b.value) - parseInt(a.value))
      : [];

  const tag = useAppSelector(state => state.storeFilters.value.data.tag);
  const dispatch = useAppDispatch();

  return (
    <Filter
      type='checkbox'
      // title='Type'
      options={typeOpts}
      initial={tag}
      onchange={(value: string) => dispatch(setTag(value))}
    />
  );
}
export default Type;
