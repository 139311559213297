/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import img from '../img/store-thumbnail.svg';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating';
import { useTranslation } from 'react-i18next';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

interface StoreThemeProps {
  title:any;
  theme:any;
  view:any;
  isSelectedActive:any;
}

const StoreTheme:React.FC<StoreThemeProps> =({ title,theme,view,isSelectedActive }: StoreThemeProps) => {
  console.log("theme",theme)
  const {t, i18n} = useTranslation()
  const [color, setColor] = useState<string>('')
  const [src,setSrc] =useState(`https://i0.wp.com/themes.svn.wordpress.org/${theme.slug}/${theme.version}/screenshot.png`)
  console.log("theme",theme)

  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist-theme ');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    if (Array.isArray(wishlist)) {
      const isAlreadyInWishlist = wishlist.some((plugins:any) =>
      plugins.id === theme.id
      );
      if (isAlreadyInWishlist) {
        // setIsPluginInWishlist(true);
        setColor('#F08921');
      }
    }
  },[theme, view]);

  const addToWishlist = () => {
    if(color === 'black'){
      setColor('F08921')
    }else{
      setColor('F08921')
    }
    console.log("pluginDetails",  theme);
    const wishlistString = localStorage.getItem('wishlist-theme');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    const isAlreadyInWishlist = wishlist.some((plugins:any) => plugins.id === theme.id);
    if (!isAlreadyInWishlist) {
      const newObj = {...theme, versions:[]}
      wishlist.push(newObj);
      localStorage.setItem('wishlist-theme', JSON.stringify(wishlist));
      toast.success('Plugin Added to wishlist');
      console.log('Added to wishlist:', theme);

    } else {
      toast.error('Already Added to wishlist');
    }
    }
    const handleImageError = () => {
      setSrc(img)
   }
  return (
    // <div className='col-sm-12 col-md-4 store-item'>
    //   {/* <img src="placeholder.png" alt="" className="store-item-image" /> */}
    //   <p className='store-item-title'>{title}</p>
    // </div>
    <div
    className={view === 'grid' ? 'col-xxl-3 col-xl-4 col-md-6 col-sm-12 plugin_columns' : 'col-md-12 listmode plugin_columns'}
  >
    <div className='plug_grid'>
      <div className='card-body'>
        <div className={view === 'grid' ? 'plugin_area' : 'plugin_area row g-3 '}>
          <div
            className={
              view === 'grid' ? 'plug_img' : 'plug_img col-sm-12 col-md-6 col-12  col-xl-3 order-md-0 order-xl-0'
            }
          >
            <div className='product_like' onClick={addToWishlist}>
                <FontAwesomeIcon icon={faHeart}  style={{color:color}} size='lg'/>
              </div>
            <img className='store-item-image img-fluid' style={{height:"auto", width:"100%" ,objectPosition:'center'}} src={src} alt='' onError={() => handleImageError()}></img>
            {/* <div className="select_favorite">
        <img className="favorite_select img-fluid" src={favorite} alt=""></img>
      </div> */}
          </div>
          <div
            className={view === 'grid' ? 'list' : 'list col-md-12 col-12 col-sm-12 col-xl-6 order-md-2 order-xl-1'}
          >
            <div className='pluginname font20'>{title}</div>
            {/* <Link to={`/plugins/${plugin.slug}`} style={{ textDecoration: 'none' }}>
              <div className='pluginname font20'>{plugin.name}</div>
            </Link> */}
            <div className='author font16'>{t('store-list.theme.By')} : {theme.wp_author}</div>
            <div className='up_date font16'>
              <span className='text-white font16'>{t('store-list.theme.Last-updated')} : </span>{' '}
              {moment(theme.updated_at).format('DD MMM YYYY') !== '01 Jan 0001'
                ? moment(theme.updated_at).format('DD MMM YYYY')
                : ''}
            </div>
            {view === 'list' ? (
              <div className='list_visible font16'>
                {/* <div className='plug_desc font16 grey-color'>
                  <span className='text-white'>{t('store-list.theme.Compatibility')} : </span> Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div> */}
                <div className='tag_cat'>
                  <div className='tag_lst'>
                    <span className='text-white my-1 font16'>{t('store-list.theme.Tags')} :&nbsp;</span>
                    <div className='tag_list cattag_lst'>
                      <span>{t('store-list.theme.Plugin')}</span>
                    </div>
                  </div>
                  {/* <div className='categories_lst'>
                    <span className='text-white my-1 font16'>{t('store-list.theme.Categories')} :&nbsp;</span>
                    <div className='cat_list cattag_lst'>
                      <span>{t('store-list.theme.Ecommerce')}</span>
                      <span>{t('store-list.theme.SEO')}</span>
                    </div>
                  </div> */}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div
            className={
              view === 'grid'
                ? 'pricesection'
                : 'pricesection col-md-6 col-12 col-sm-12 col-xl-3 order-md-1 order-xl-2 border-sm-width-0 border-md-width-0 border-xl-width-1 justify-content-sm-start justify-content-md-center'
            }
          >
            <div className='row'>
              <div className='col-12 col-md-6'>
                {/* {renderRating()} */}

          <Rating initialValue={theme.rating/20} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" />
              </div>
              <div className='col-12 col-md-6 align-self-end'>
                <div className='plugin_select_btn'>
                  {/* {active !== null ? (
            <button className={active ? 'orange_btn ms-auto' : 'cblue_btn ms-auto'}
              onClick={(e: any) => {
                if (active) addremove(plugin, e);
                else addremove({...theme,projects:existinProject}, e);
              }}
            >
              {active ? <span>Remove</span> : <span>Select</span>}
            </button>
          ) : null} */}
                  {/* <button className="cblue_btn ms-auto" type="button" disabled={queryString.get("project_id") !== null ? queryString.get("project_id") !== ""?false:true:true} onClick={(e) => onAddPlugin(plugin,e)}>
            {isSelectedActive ? "Remove":"Select"}
          </button> */}
                  {/* <button
                    className='cblue_btn ms-auto'
                    type='button'
                    style={{ zIndex: 9999999999999 }}
                    onClick={e => onAddPlugin(plugin, e)}
                  >
                    {isSelectedActive ? 'Remove' : 'Select'}
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
export default StoreTheme;
