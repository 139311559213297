/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useGetDeployQueueDataQuery, useProjectQuery } from '../features/api';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from './Loader';
// import { Modal } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
import { useAppSelector } from '../features/hooks';

// import Header from './Header';
import { useTranslation } from 'react-i18next';
import CommonModal from './CommonModal';

// const envList = ['Production', 'Staging', 'QA'];


const ComposerView:React.FC = () =>  {
  const [projectId, setProjectId] = useState('0');

  const [envId, setEnvid] = useState('0');
  const [filteredEnv, setfilteredEnv] = useState([]);
  const [composerData, setComposerData] = useState<any>();
  const [project, setProject] = useState([]);
  const [storeMeta, setStoreMeta] = useState<any>({})

  const { data:userdetails } = useAppSelector((state:any) => state.userDetails);
  const { data: projectsList } = useProjectQuery({
    data: {},
  });
  const {t} = useTranslation()
  const [updateQueueComposer, setUpdateQueueComposer] = useState<any>({});
  // const getComposerQueue =
  //   localStorage.getItem('composer-queue') !== null ? JSON.parse(localStorage.getItem('composer-queue')!) : [];
  // const queueComposerUpdater: any =
  //   localStorage.getItem('composer-plugin-add') !== null
  //     ? JSON.parse(localStorage.getItem('composer-plugin-add')!)
  //     : [];
  const paramsString = useParams();
  const queryString = new URLSearchParams(window.location.search);
  const { data: queueData, isLoading: projectLoad } = useGetDeployQueueDataQuery({});
  const [storeQueue, setStoreQueue] = useState<Array<any>>([]);
  const [authModel, setAuthModel] = useState<boolean>(false);
  const [authText, setAuthText] = useState<string>('');


  const navigate = useNavigate();

  const authConf=`{
    "fusepress.com": {
      "username": "Paste your username here",
      "password": "Paste your password here"
    }
  }`

  useEffect(() => {
    if (typeof queueData !== 'undefined') {
      if (queueData.length > 0) {
        setStoreQueue(queueData);
      }
    }
  }, [queueData]);

  useEffect(() => {
    queueGenerator();
  }, [
    projectsList,
    queryString.get('project_id'),
    queryString.get('environment'),
    queryString.get('type'),
    paramsString,
    queueData,
    storeQueue,
  ]);
  useEffect(() => {
    if (projectsList) {
      setProject(projectsList?.projects);
    }
  }, [projectsList]);

  function queueGenerator(): void {
    const project_id: any = queryString.get('project_id');
    if (paramsString.typeId === 'deploy-using-composer-file') {
      if (project_id !== null && typeof project_id !== 'undefined') {
        if (project_id.length > 0) {
          if (storeQueue.length > 0) {
            const getProjectQueue = storeQueue.find((i: any) => {
              return (
                i.project.project_id === parseInt(project_id) &&
                i.enviornments.id === parseInt(queryString.get('environment') as any)
              );
            });

            if (getProjectQueue !== undefined) {
              const meta = JSON.parse(getProjectQueue.enviornments.meta);
              console.log(meta.auth)
              if (!meta.auth['http-basic']) {
                meta.auth['http-basic'] = {};
              }
              // meta.auth['http-basic']['files.fusepress.co'] = {
              //   username: 'username',
              //   password: 'password',
              // };

              console.log(meta)
              setStoreMeta(meta)
              setComposerData(meta.composer);
              setProjectId(project_id);

              const modifiedObject = getProjectQueue.plugins.reduce((result: any, obj: any) => {
                result[`${obj.type}/${obj.slug}`] = obj.plugin_version;
                return result;
              }, {});
              setUpdateQueueComposer(modifiedObject);
              const projectIdNum = projectsList?.projects?.find((i: any) => {

                return i.name === getProjectQueue.project.project_name;
              });
              if (projectIdNum !== undefined) {
                let posibleEnv = projectIdNum.meta.map((env: any, index: number) => {
                  return { id: env.meta.id, envname: env.meta.name };
                });
                const env_id: any = queryString.get('environment');

                setEnvid(env_id);
                setfilteredEnv(posibleEnv);
              }
            }
          }
        }
      }
    } else {
      if (typeof window !== 'undefined') {
        window.alert('Other methods are in development');
      }
    }
  }






  const handleZipDownload = (jsonObject: Record<string, any>) => {
    const zip = new JSZip();

    Object.keys(jsonObject).forEach((key) => {
      const jsonData = jsonObject[key];
      const fileName = `${key}.json`;
      zip.file(fileName, JSON.stringify(jsonData, null, 2));
    });

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${    queryString.get('environment')
    }-${queryString.get('project_id')}.zip`);
    });
  };



  const onDeployDownload = (): void => {
    const composer_Json = composerData;
    if (composer_Json.repositories) {
      Object.values(composer_Json.repositories).forEach((repository:any) => {
        if (repository.url === 'https://serenichron.com/satispress/') {
          repository.url = `https://files.fusepress.co/serenichron/${userdetails.id}/`;
        }
      });
    }

    storeQueue && storeQueue.forEach((item: any) => {
      item.plugins.map((plugin: any) => {
        // const payload = {
        //   slug: plugin.slug,
        //   project_env_id: item.enviornments.id,
        //   flag: "deploy",
        // };

        // axios({
        //   url: `${process.env.REACT_APP_API_URL}/api/deleteQueueData`,
        //   method: 'POST',
        //   data: payload,
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem('token')}`,
        //   },
        // })
        // .then((res1: any) => {
        //   if (Object.keys(res1).length > 0) {
        //     if (res1.data.hasOwnProperty('error')) {
        //       toast.error(`${res1.data.message}`);
        //     } else {
        //       toast.success(`${res1.data.message}`);
        //     }
        //   }
        // })
      })
    })
    if (queryString.get('type') === 'composer_queue') {
      if (Object.keys(updateQueueComposer).length > 0) {
        for (const key in composer_Json.require) {
          if (Object.prototype.hasOwnProperty.call(updateQueueComposer, key)) {
            composer_Json.require[key] = updateQueueComposer[key];
          }
        }
        for (const key in updateQueueComposer) {
          if (
            Object.prototype.hasOwnProperty.call(updateQueueComposer, key) &&
            !Object.prototype.hasOwnProperty.call(composer_Json, key)
          ) {
            composer_Json.require[key] = updateQueueComposer[key];
          }
        }
      }
      console.log("composer_Json",composer_Json);




      // uploadDeployComposer(composer_Json);
      // downloadComposer(composer_Json);
      // navigate('/projects')
      console.log(storeMeta)
      // if(Object.prototype.hasOwnProperty.call(storeMeta,"auth")){
      //            const newAuth:any = storeMeta?.auth
      //            console.log("newAuth",newAuth)

      //            newAuth['http-basic']['files.fusepress.co'] = {
      //              username: "username",
      //              password: "password"
      //          };
      //            console.log(newAuth,"newAuth")
      //          } else {
      //            const authJson = {
      //              'http-basic': {
      //                'fusepress.com': {
      //                  username: 'fusepress',
      //                  password: 'password',
      //                },
      //              },
      //            };
      //        }

             const filesObject = {
              'auth': storeMeta.auth,
              'composer': composer_Json,
              // ...(Object.prototype.hasOwnProperty.call(storeMeta,"composer_lock") && { 'composer.lock': storeMeta.composer_lock }),
            };
            handleZipDownload(filesObject)
            setAuthModel(false)
            // console.log(filesObject)
      console.log("composer_Json",composer_Json);
      uploadDeployComposer(composer_Json);

      // downloadComposer(composer_Json);
      // navigate('/projects')
    } else if (queryString.get('type') === 'add_plugin_queue_composer') {
      // setAuthModel(false)
      if (Object.keys(updateQueueComposer).length > 0) {
        for (const key in updateQueueComposer) {
          if (
            Object.prototype.hasOwnProperty.call(updateQueueComposer, key) &&
            !Object.prototype.hasOwnProperty.call(composer_Json, key)
          ) {
            composer_Json.require[key] = updateQueueComposer[key];
          }
        }
      }
      // downloadComposer(composer_Json);
      // navigate('/projects')
    }
  };

  function generateAuthToken(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
    for (let i = 0; i < length; i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  }

  function uploadDeployComposer(composer: any): void {
    const composer_meta = {
      type: 'composer',
      composer: composer,
      file_path: '',
    };
    const compArray = Object.keys(composer.require).map((i: any) => {
      const ab = i.split('/');
      if (ab.length === 1) {
        return {
          type: '',
          plugin: ab[0],
          version: composer.require[i],
        };
      } else {
        return {
          type: ab[0],
          plugin: ab[1],
          version: composer.require[i],
        };
      }
    });
    const jsonBlob = new Blob([JSON.stringify(composer, undefined, 4)], {
      type: 'application/json',
    });
    const file = new File([jsonBlob], 'updated-composer.json', { type: jsonBlob.type });

    const formData = new FormData();
    formData.append('composer_file', file);
    formData.append('composer_require_array', JSON.stringify(compArray));
    formData.append('meta', JSON.stringify(composer_meta));
    formData.append('project_env_id', parseInt(queryString.get('environment') as any) as any);
    formData.forEach((value, key) => {
      console.log(key, value);
    });
    const authJson = {
      'http-basic': {
        'fusepress.com': {
          username: 'fusepress',
          password: generateAuthToken(32),
        },
      },
    };
    setAuthText(JSON.stringify(authJson, null, 2));
    // onAuthModelOpen();
    axios({
      url: `${process.env.REACT_APP_API_URL}/api/deployProjectComposer`,
      method: 'POST',
      data: formData,
      headers: {
        // Accept: "multipart/form-data",
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res1: any) => {
        if (Object.keys(res1).length > 0) {
          if (res1.data.hasOwnProperty('error')) {
            toast.error(`${res1.data.message}`);
          } else {
            navigate('/projects')
            toast.success(`${res1.data.message}`);
          }
        }
      })
      .catch((err1: any) => {
        console.log('err1', err1);
      });
  }

  function downloadComposer(composer: any): void {
    if (typeof document !== 'undefined') {
      const download: any = document.getElementById('download_composer');
      const jsonBlob = new Blob([JSON.stringify(composer, undefined, 4)], {
        type: 'application/json',
      });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(jsonBlob);
      downloadLink.download = 'updated-composer.json';
      download.appendChild(downloadLink);
      downloadLink.click();
      download.removeChild(downloadLink);
    }
  }

  const downloadComposerFromText = (): void => {
    if (typeof document !== 'undefined') {
      const composerText = document.getElementById('original_composer');
      if (composerText instanceof HTMLTextAreaElement) {
        downloadComposer(JSON.parse(composerText.value));
      }
    }
  };
  const onAuthModelClose = (): void => {
    setAuthModel(false);
  };

  const onAuthModelOpen = (): void => {
    setAuthModel(true);
  };

  const onDownloadAuthJSON = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (typeof document !== 'undefined') {
      const download: any = document.getElementById('download_auth_json');
      const jsonBlob = new Blob([authText], {
        type: 'application/json',
      });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(jsonBlob);
      downloadLink.download = 'auth.json';
      download.appendChild(downloadLink);
      downloadLink.click();
      download.removeChild(downloadLink);
      navigate({
        pathname: `/projects/${projectId}`,
        search: createSearchParams({
          environment: envId,
        }).toString(),
      });
    }
  };
  const onOpenModal =()=>setAuthModel(true)

  if (projectLoad) {
    return <Loader />;
  }



  return (
    <main className='main main-side-sort'>
      <div className='container-fluid'>
        <form className='addproject my-4 mw-100'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='input-group mb-3'>
                <span className='input-group-text'>{t('composer-deploy.tags.project')}</span>
                <select
                  className='form-select'
                  id='inputGroupSelect01'
                  // placeholder="Select project"
                  value={projectId}
                  onChange={e => setProjectId(e.target.value)}
                >
                  <option value='0'>Select Project</option>
                  {project?.map((p: any) => (
                    <option key={p.id} value={p.id}>

                      {p.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input-group mb-3'>
                <span className='input-group-text'>{t('composer-deploy.tags.env')}</span>
                <select
                  className='form-select'
                  id='inputGroupSelect01'
                  // placeholder="Select environment type"
                  value={envId}
                  onChange={e => setEnvid(e.target.value)}
                >
                  <option value='0'>Select Environment</option>
                  {filteredEnv?.map((env: any) => (
                    <option value={env.id} key={env.id}>
                      {env.envname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </form>
        <div className='row'>
          <div className='col-md-6'>
            <h6 className='text-white'>{t('composer-deploy.tags.project-composer')}</h6>
            <div className='form-floating'>
              <textarea
                className='form-control composer-content min-vh-100'
                id='original_composer'
                defaultValue={composerData ? JSON.stringify(composerData, undefined, 4) : ''}
              />
              <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
            </div>
          </div>
          <div className='col-md-6'>
            <h6 className='text-white'>
              {queryString.get('type') === 'add_plugin_queue_composer'
                ? t('composer-deploy.tags.add')
                : queryString.get('type') === 'composer_queue'
                ? t('composer-deploy.tags.update')
                : null}
            </h6>
            <div className='form-floating'>
              {/* {/ <textarea className="form-control composer-content min-vh-100" id="floatingTextarea2" readOnly defaultValue={(composerData?JSON.stringify(JSON.parse(composerData.composer_queue), undefined,4):'')}/> /} */}
              <textarea
                className='form-control composer-content min-vh-100'
                id='floatingTextarea2'
                readOnly
                defaultValue={composerData ? JSON.stringify(updateQueueComposer, undefined, 4) : ''}
              />
              <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
            </div>
          </div>
        </div>
        <div className='text-end d-flex justify-content-end my-4'>
          <button className='cblue_btn d-flex align-items-center' disabled={composerData ? false : true}>
            <span className='d-inline-block ms-1' onClick={() => downloadComposerFromText()}>
            {t('composer-deploy.buttons.download-composer')}
            </span>
          </button>
          <button
            className='orange_btn d-flex align-items-center ms-2'
            onClick={()=>onOpenModal()}
            disabled={composerData ? false : true}
          >
            <span className='d-inline-block ms-1'>{t('composer-deploy.buttons.deploy')}</span>
          </button>
          <button className='orange_btn d-flex align-items-center ms-2'>
            <span className='d-inline-block ms-1'>{t('composer-deploy.buttons.close')}</span>
          </button>
          <div id='download_composer'></div>
          <div id='download_auth_json'></div>
        </div>
      </div>


      <CommonModal className='composer-model' show={authModel} onHide={onAuthModelClose} animation={false} size='sm'>
        <CommonModal.Header>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <h4 className='text-white'  style={{ marginBottom: '5px' }}>{t('composer-deploy.modals.authentication_file')}</h4>
            <div className="alert alert-warning" role="alert" >
                  <FontAwesomeIcon icon={faExclamationTriangle} style={{position:'absolute',left:'15px',top:'35px'}}/>
                  <p style={{marginLeft:'35px'}}>
                  {t('composer-deploy.modals.alert_message')}
                  </p>
                </div>
            <FontAwesomeIcon icon={faClose} onClick={onAuthModelClose} style={{ color: 'white' }} size='lg' />
          </div>
        </CommonModal.Header>
        <CommonModal.Body>
          <div>
            <form className='addproject' >
              <div className='input-group mb-3'>
                <span className='input-group-text'>Auth JSON</span>
                <textarea
                  className='form-control'
                  readOnly
                  cols={4}
                  rows={10}
                  placeholder='Description'
                  name='description'
                  value={authConf}
                  // onChange={e => setAuthText(e.target.value)}
                ></textarea>
              </div>
              <div className='d-flex justify-content-end my-5'>
                <button type='button' className='orange_btn mx-1' onClick={onDeployDownload}>Deploy</button>
              </div>
            </form>
          </div>
        </CommonModal.Body>

      </CommonModal>
    </main>
  );
}

export default ComposerView;


