/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SetStateAction, useEffect, useRef } from 'react';
import "../../src/sass/pages/subscriptions.scss";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';

interface CommonModalProps {
  onHide: React.Dispatch<SetStateAction<boolean>>;
  show?: boolean;
  message:string;
  clickFunc:any;
}

// function useOutsideClick(ref: any, handler: any) {
//   useEffect(() => {
//     const listener = (event: any) => {
//       if (!ref.current || ref.current.contains(event.target)) {
//         return;
//       }
//       handler(event);
//     };
//     document.addEventListener('mousedown', listener);
//     return () => {
//       document.removeEventListener('mousedown', listener);
//     };
//   }, [ref, handler]);
// }

const CommonAlert: React.FC<CommonModalProps> & {
} = ({  onHide, show, message ,clickFunc}) => {
  const modalRef: any = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
      document.body.setAttribute("data-rr-ui-modal-open", '');
    } else {
      document.body.classList.remove('modal-open');
      document.body.removeAttribute("data-rr-ui-modal-open");
    }
  }, [show]);


  const handleClose = () => {
    onHide(false);
  };

  return (
    <>
      {show ? <div className='modal-backdrop show'></div> : null}

      <div role='dialog'
        style={{ display: show ? 'block' : 'none' }}
        className={`composer-model modal fade ${show ? 'show' : 'hide'}`}
        id="commonModal"
        tabIndex={-1}
        aria-labelledby="commonModalLabel"
        aria-hidden="true"
      >
        <div className="composer-model modal-dialog modal-lg" ref={modalRef}>
          <div className="modal-content composer-model alert-warning" style={{ width:'500px', backgroundColor:'#c3c3c3', padding:' 22px 40px 22px 40px'}}>
            <div className="modal-body">
              <div className='d-flex justify-content-end' style={{marginBottom:'25px'}}>
            <FontAwesomeIcon icon={faXmark} color='black' size='lg' onClick={handleClose}/>
              </div>
                <div className='d-flex align-items-center gap-2' style={{marginBottom:'30px'}}>      
              <FontAwesomeIcon icon={faCircleExclamation} color='black' size='lg'/>
              <p
              style={{
                fontSize:'20px',
                color:'black',
                margin:'0px'
              }}
              >{message}</p>
                </div>
              <div className='d-flex gap-2 justify-content-end'>
              <button className='orange_btn' onClick={clickFunc}>Yes</button>
              <button className='remove_btn' style={{height:'40px'}} onClick={handleClose}>No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonAlert;
