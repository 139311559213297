import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import fusepress_logo from '../img/fusepress_logo.png';
import { useUserCreateMutation, useUserDetailsQuery } from '../features/api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SetPassword:React.FC = () => {
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [passwordShowOne, setPasswordShowOne] = useState<boolean>(false);
  const [icon, setIcon] = useState<any>(faEyeSlash);
  const [iconOne, setIconOne] = useState<any>(faEyeSlash);
  const [passwordText, setPasswordText] = useState<any>({ password: '', confirm_password: '' });
  const query = new URLSearchParams(window.location.search);
  const [userCreate] = useUserCreateMutation();
  const navigate = useNavigate();
  const { data: userdetails } = useUserDetailsQuery({});

  const togglePassword = (): void => {
    setPasswordShow(!passwordShow);
    if (passwordShow) {
      setIcon(faEyeSlash);
    } else {
      setIcon(faEye);
    }
  };

  const togglePasswordOne = (): void => {
    setPasswordShowOne(!passwordShowOne);
    if (passwordShowOne) {
      setIconOne(faEyeSlash);
    } else {
      setIconOne(faEye);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const data1 = {
      email: query.get('email'),
      provider: query.get('provider'),
      password: passwordText.password,
      flag: 'password_create',
    };
    userCreate(data1).then((res: any) => {
      if (res.data.passwordVal) {
        const email = data1.email || null;
        const atIndex = email?.indexOf('@');
        if (atIndex !== -1) {
          const emailBeforeAt = email?.substring(0, atIndex);
          axios({
            url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
            method: 'GET',
            headers: {},
            params: {
              action: 'get_user_data',
              fname: userdetails.displayName,
              lname: userdetails.displayName,
              username: emailBeforeAt,
              password: btoa(passwordText.password),
              email: email,
              role: 'customer',
            },
            data: {},
          })
            .then((res1: any) => {
              console.log('res1', res1.data);
              localStorage.setItem('token', res.data.token);
              navigate('/');
            })
            .catch((err1: any) => {
              console.log('err', err1);
            });
        }
        // const queryParams = new URLSearchParams({
        //   fname: query.get("fname") || '',
        //   lname: query.get("lname") || '',
        //   username: query.get("username") || '',
        //   password: btoa(res.data.passwordVal),
        //   email: query.get("email") || '',
        //   role: "customer",
        // });
        // const newUrl = `${baseUrl}&?${queryParams.toString()}`;
        // window.location.href = newUrl;
        // setTimeout(() => {
        //   navigate('/');
        // }, 2000);
      }
    });
  };
  return (
    <main className='main'>
      <div className='main_auth_inner'>
        <div className='home_logo'>
          <img src={fusepress_logo} alt='fusepress_logo' />
        </div>
        <div className='fp_custom_login'>
          {query.get('email') !== null ? (
            <form onSubmit={handleSubmit}>
              <div className='input-container'>
                <p className='text-white'>
                  Set Password For <strong>{query.get('email')}</strong>
                </p>
                {/* <FontAwesomeIcon icon={faEnvelope} className="fa fa-envelope icon" /> */}
                {/* <input type="email" className="input-field" placeholder='Email Id' name="email" onChange={handleChange} value={formData.email} /> */}
              </div>
              <div className='input-container'>
                <FontAwesomeIcon icon={faLock} className='fa fa-lock icon' />
                <input
                  type={passwordShow ? 'text' : 'password'}
                  className='input-field'
                  placeholder='Password'
                  name='password'
                  onChange={e => setPasswordText({ ...passwordText, password: e.target.value })}
                  value={passwordText.password}
                />
                <FontAwesomeIcon icon={icon} className='icon icon_sec' onClick={togglePassword} />
              </div>
              <div className='input-container'>
                <FontAwesomeIcon icon={faLock} className='fa fa-lock icon' />
                <input
                  type={passwordShowOne ? 'text' : 'password'}
                  className='input-field'
                  placeholder='Confirm Password'
                  name='password'
                  onChange={e => setPasswordText({ ...passwordText, confirm_password: e.target.value })}
                  value={passwordText.confirm_password}
                />
                <FontAwesomeIcon icon={iconOne} className='icon icon_sec' onClick={togglePasswordOne} />
              </div>
              <div className='forget_password'></div>
              <button className='orange_btn w-100 mt-3'>Submit</button>
            </form>
          ) : (
            <>
              <h2 style={{ color: 'white' }}>You need to have Email</h2>
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default SetPassword;
