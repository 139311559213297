import React from 'react';
import { useAppSelector, useAppDispatch } from '../features/hooks';

import { setVendor } from '../features/storeFilters';

import Filter from '../components/Filter';

export interface VendorInterface {
  vendorData: any;
}

const Vendors:React.FC<VendorInterface> = (props: VendorInterface) => {
  // const vendorOpts = [
  //   {
  //     value: '1',
  //     name: 'Vendor 1',
  //   },
  //   {
  //     value: '2',
  //     name: 'Vendor 2',
  //   },
  //   {
  //     value: '3',
  //     name: 'Vendor 3',
  //   },
  //   {
  //     value: '4',
  //     name: 'Vendor 4',
  //   },
  // ];

  const vendorOpts =
    typeof props.vendorData === 'object' && props.vendorData !== null
      ? props.vendorData
          .map((value: any, index: number) => {
            return {
              value: value.author,
              name: value.author,
              length: value.count,
            };
          })
          .sort((a: any, b: any) => parseInt(b.value) - parseInt(a.value))
      : [];

  const vendor = useAppSelector(state => state.storeFilters.value.data.vendor);
  const dispatch = useAppDispatch();

  return (
    <Filter
      type='checkbox'
      title='Type'
      options={vendorOpts}
      initial={vendor}
      onchange={(value: string) => dispatch(setVendor(value))}
    />
  );
}
export default Vendors;
