/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// import { useAppSelector } from '../features/hooks';
import Breadcrumbs from './Breadcrumbs';
// import { Modal, Form, Button, CloseButton } from 'react-bootstrap';
// import ImageUploading from 'react-images-uploading';
import { useFeedbackMutation } from '../features/api';
import { toast } from 'react-toastify';
import { send } from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircleXmark, faClock, faSquareCaretDown,  faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationData } from '../features/getNotificationList';
import moment from 'moment';
import { fetchUserDetails } from '../features/userDetailsSlice';
import { useAppSelector } from '../features/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import SubscriptionModal from './SubscriptionModel';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Header:React.FC = () => {
  const [showModal, setShow] = useState(false);
  const [subscription_status, setsubscription_status] = useState<any>('')
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const [showContainer, setShowContainer] = useState(false);
  const [hideEmoji, setHideEmoji] = useState(true)
  const [wordpressLoginUrl, setWordpressLoginUrl] = useState<string>('');
  const location = useLocation();
  let auth = { token: localStorage.getItem('token') !== null };
  const token = localStorage.getItem('token') || '';
  const storePlugins = localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const excludeModalRoutes = ['/reset-password', '/set-password', '/forgot-password','/auth','/authOtp'];
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchNotificationData())
    dispatch(fetchUserDetails())
  },[dispatch])

  const {t} = useTranslation()

  const {data:notification} = useSelector((state:any) => state.getNotification)
  const { data:userdetails } = useAppSelector((state:any) => state.userDetails);
  useEffect(() => {
    if (userdetails && Object.keys(userdetails).length > 0) {
      setUserDetails(userdetails);
    }
  }, [userdetails]);

  useEffect(() => {
    if (typeof userdetails !== 'undefined') {
      const fetchLoginUrl = async () => {
        try {
          const url = (await loginToWordPress()) || '';
          localStorage.setItem('wordpressloginurl', url);
          setWordpressLoginUrl(url)

        } catch (error) {
          setWordpressLoginUrl('')
          console.error('Error fetching login URL:', error);
        }
      };

      fetchLoginUrl();
    }
  }, [userdetails]);


  const loginToWordPress = async (): Promise<string | null> => {
    try {
      const formData = new FormData();
      formData.append('Email', userdetails.email);
      formData.append('token', token);
      // formData.append("Password",atob(data.password))
      const response = await axios.post(
        `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/signin-user/myaccount`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const redirectUrl = response.data;
      if (redirectUrl === 'Invalid login credentials.') {
        const email = userdetails.email;
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
          const emailBeforeAt = email.substring(0, atIndex);
          axios({
            url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
            method: 'GET',
            headers: {},
            params: {
              action: 'get_user_data',
              fname: userdetails.displayName,
              lname: userdetails.displayName,
              username: emailBeforeAt,
              email: userdetails.email,
              role: 'customer',
            },
            data: {},
          })
            .then(res1 => {
              console.log('res1', res1);
            })
            .catch((err1: any) => {
              console.log('err1', err1);
            });
        }
        return redirectUrl;
      } else {
        return redirectUrl;
      }
    } catch (error) {
      console.error('Error during login:', error);
      return null;
    }
  };

  const redirecttoAccounts = () => {
    console.log("hello",wordpressLoginUrl)
    if(wordpressLoginUrl.length > 0){
      window.open(wordpressLoginUrl,"_blank")
    }else {
      toast.error("something went wrong")
    }
  }

  useEffect(() => {
    if (userDetails && userDetails.subscription_status !== undefined) {
      // console.log(userDetails);
      setsubscription_status(userDetails.subscription_status);

    }
  }, [userDetails]);

  useEffect(() => {
    if (auth.token && !excludeModalRoutes.includes(location.pathname)  && subscription_status !=='active') {
      // setIsModalOpen(true);
      // document.body.classList.add('no-scroll');
      setIsToastOpen(true)
    } else {
      // setIsModalOpen(false);
      setIsToastOpen(false)
      // document.body.classList.remove('no-scroll');
    }
  }, [auth.token, location.pathname, excludeModalRoutes, subscription_status]);

  console.log("notification",notification)

  // useEffect(() => {
  //   console.log("window.location.pathname",window.location.pathname)
  //   const pathtoAvoid = ['/home','/auth','/authOtp','/forgot-password','/reset-password','/set-password','/not-found']
  //   console.log("hjdfhdjsfh",pathtoAvoid.includes(window.location.pathname))
  //   if(pathtoAvoid.includes(window.location.pathname)){
  //     setHideEmoji(false)
  //     // setShowContainer(false);
  //   } else{
  //     setHideEmoji(true)
  //   }
  // },[window.location.pathname])

  const [showNotifications, setShowNotifications] = useState(false);
  const [image, setImage] = useState([]);
  const {i18n} = useTranslation()
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    images: [],
  });
  const [notifyList,setNotifyList] = useState<Array<any>>([])

  if(window.location.pathname.includes('/store') && !window.location.pathname.includes('/store/queue')){
    const header:any = document.getElementsByClassName('header');
    if (header.length > 0) {
      header[0].classList.add('store_header');
    }
  }else{
    const header:any = document.getElementsByClassName('header');
    if (header.length > 0) {
      header[0].classList.remove('store_header');
    }
  }

  useEffect(() => {
    if(notification !== null){
      setNotifyList(notification.data)
    }

  },[notification])
  const [Feedback] = useFeedbackMutation();
  const onChange = (imageList: any) => {
    setFormData({
      ...formData,
      images: imageList.map((obj: any, i: any) => {
        return imageList[i]?.file?.name;
      }),
    });
    setImage(imageList);
  };
  const img = image.map(images => {
    return images['data_url'];
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleClose();
    let data = {
      title: formData.title,
      description: formData.description,
      images: formData.images,
    };
    let data1 = {
      title: formData.title,
      description: formData.description,
      images: img[0],
    };
    Feedback(data)
      .then((res: any) => {
        if (Object.keys(res).length >= 0) {
          if (res.data.hasOwnProperty('error')) {
            toast.error(`${res.data.message}`);
          } else {
            send(
              `${process.env.REACT_APP_SERVICE_ID}`,
              `${process.env.REACT_APP_TEMPLATE_ID}`,
              data1,
              `${process.env.REACT_APP_PUBLIC_KEY}`
            );
            toast.success(`${res.data.message}`);
          }
        }
      })
      .catch((err: any) => {
        toast.error(`${err}`);
      });
  };
  useEffect(() => {
    const handleScroll = () => {
      setShowNotifications(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // let sidebar_width = document.querySelector('.sidebar')?.getBoundingClientRect().width;
  // let asid_width =document.querySelector('.first-aside')
  // const window_width = window.innerWidth;
  // const userPlugins = useAppSelector(state => state.userplugins.value.plugins);


  // const [width, setWidth] = useState(0);
  // let res: any;
  // useEffect(() => {
  //   const sidebar_width:any = document.querySelector('.sidebar')?.getBoundingClientRect().width;
  //   let asid_width =document.querySelector('.first-aside')
  //   const window_width = window.innerWidth;
  //   console.log('window_width',window_width);
  //   console.log('sidebar_width',sidebar_width);
  //   if (sidebar_width && (!window.location.pathname.includes('/store')|| window.location.pathname.includes('/store/queue'))) {
  //     res = window_width - sidebar_width -74;
  //     console.log("store_width", res);
  //   }else{
  //     res = window_width - sidebar_width -15;
  //     console.log("store_width", res);
  //   }
  //   console.log("res_width",res)
  //   setWidth(res);
  // }, [window.location.href]);

  const changeLanguage = (text:string):void => {
    localStorage.setItem('language',text)
    // const lang = i18n.language === "en"? "ro" : "en"
    i18n.changeLanguage(text)
  }

  const logout = () => {
    localStorage.removeItem('token');
    // window.location.replace(process.env.REACT_APP_WORDPRESS_STAGING_URL + '/wp/wp-login.php?action=logout');
    // navigate('/auth');
    localStorage.setItem("syncLock","0")
    const logoutWindow = window.open(
      process.env.REACT_APP_WORDPRESS_STAGING_URL + '/wp/wp-login.php?action=logout',
      '_blank'
    );
    setTimeout(() => {
      if (logoutWindow) {
        logoutWindow.close();
        window.location.reload();
        navigate('/auth');
      }
    }, 2000);
  };

  const toggleContainer = () => {
    setShowContainer(!showContainer);
  };
  const closeContainer = ()=>{
    setShowContainer(false)
  }
  const redirecttoQueue = () => {
    navigate('/store/queue')
  }

  return (
    <header className='header ps-4'>
      <div className='container-fluid p-0'>
        <div className='row align-items-center m-0'>
          <div className='col'>
            <div className='breadcrumb mb-1'>
              <Breadcrumbs />
            </div>
          </div>

          <div className='col'>

            <div className='text-end d-flex justify-content-end'>
            {/* <div className="icon-container"  style={{position:'absolute',top:'10px',height:'32px'}}>
        <FontAwesomeIcon icon={faUser} />
      </div>
      <div className="dropdown-content">
        <a href="#profile">Profile</a>
        <a href="/wishlist">Wishlist</a>
        <span onClick={() => logout()}>Logout</span>

      </div> */}
        <div className="dropdown" style={{position:'absolute',top:'10px',height:'32px'}}>
         <div className="icon-container" style={{height:'32px'}}>
        <FontAwesomeIcon icon={faUser} />
      </div>
      <div className="dropdown-content">
        <span onClick={() =>  redirecttoAccounts()}>Profile</span>
        <Link to='/wishlist'>
        Wishlist
        </Link>
        <span onClick={() => logout()}>Logout</span>

      </div>
      </div>
            {/* <button className='orange_btn mx-2' onClick={() => changeLanguage('en')}>EN</button>
            <button className='cblue_btn' onClick={() => changeLanguage('ro')}>RO</button> */

            <div className='d-block' style={{ position: "absolute", top: "10px", right: "60px", }}>

            <select
                className='language-select environment-select form-select orange'
                onChange={(e) => changeLanguage(e.target.value)}
                value={i18n.language}
                style={{
                    padding: "4px 30px 4px 10px",
                    fontSize: "16px",
                    backgroundColor: "#f08921",
                    color: "white",
                    appearance: "none",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    transition: "color 0.3s",
                    borderRadius: "5px"


                }}
            >
                <option value="en" style={{
                    color: "#f08921",
                    border: "none",
                    borderRadius: "5px"}}>EN</option>
                <option value="ro" style={{
                    color: "#f08921",
                    border: "2px solid red",
                    borderRadius: "5px"}}>RO</option>
                <option value="es" style={{
                    color: "#f08921",
                    borderRadius: "5px"}}>ES</option>
                <option value="it" style={{
                    color: "#f08921",
                    borderRadius: "5px" }}>IT</option>
                <option value="sv" style={{
                    color: "#f08921",
                    borderRadius: "5px" }}>SV</option>
                <option value="el" style={{
                    color: "#f08921",
                    borderRadius: "5px" }}>EL</option>
                <option value="de" style={{
                    color: "#f08921",
                    borderRadius: "5px" }}>DE</option>
                  <option value="nl" style={{
                    color: "#f08921",
                    borderRadius: "5px" }}>NL</option>
            </select>

        </div>

            }
             <button className=" d-flex align-items-center justify-content-center composer-project-details-second header_notification "  onClick={toggleNotifications} style={{right:'130px'}}>
             <div className='badge' style={{position:'absolute', backgroundColor:'#F08921', height:'18px', color:'white', width:'18px', top:'-8px', right:'-8px', borderRadius:'50%',padding:'0px',paddingTop:'2px'}}>{notifyList.length}</div>
            <span className="d-inline-block lh-1">
              <svg className="m-0" width="19" height="18" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.37922 0.006C9.43122 0.029 9.47922 0.058 9.52822 0.081C9.68766 0.146568 9.82456 0.257127 9.92223 0.399185C10.0199 0.541243 10.0741 0.708658 10.0782 0.881C10.0892 1.168 10.0842 1.455 10.0782 1.742C10.0782 1.863 10.1122 1.914 10.2382 1.942C11.5176 2.15505 12.7016 2.75346 13.6318 3.65727C14.5621 4.56109 15.1944 5.72727 15.4442 7C15.5249 7.4596 15.5654 7.92537 15.5652 8.392C15.5882 9.362 15.5482 10.332 15.6052 11.292C15.6503 12.0819 15.8535 12.8547 16.2027 13.5646C16.5519 14.2746 17.04 14.9072 17.6382 15.425C17.8205 15.5645 17.9731 15.7389 18.0871 15.9381C18.2011 16.1373 18.2742 16.3572 18.3022 16.585C18.3283 16.8064 18.3076 17.0307 18.2415 17.2436C18.1754 17.4564 18.0654 17.653 17.9185 17.8207C17.7717 17.9884 17.5913 18.1234 17.389 18.2169C17.1867 18.3105 16.9671 18.3606 16.7442 18.364C16.6869 18.3669 16.6295 18.3669 16.5722 18.364H1.74422C1.47535 18.3798 1.20666 18.3301 0.961238 18.2191C0.715816 18.1082 0.500994 17.9393 0.335225 17.727C0.0792197 17.3945 -0.0359366 16.9748 0.0145664 16.5583C0.0650694 16.1417 0.277189 15.7617 0.605225 15.5C1.20679 14.9756 1.70413 14.3426 2.07122 13.634C2.53004 12.7368 2.76408 11.7416 2.75322 10.734C2.75322 9.85 2.74222 8.972 2.75922 8.088C2.79652 6.6108 3.34384 5.19201 4.30827 4.07246C5.2727 2.95291 6.59482 2.20158 8.05022 1.946C8.21622 1.917 8.26222 1.854 8.25622 1.699C8.24522 1.527 8.26722 1.355 8.25022 1.182C8.20367 0.933726 8.24889 0.676994 8.37747 0.459567C8.50605 0.24214 8.70924 0.0788233 8.94922 0C9.09222 0.006 9.23622 0.006 9.37922 0.006Z" fill="#909090"></path>
              <path d="M5.85547 19.286H12.4625C12.4175 20.586 10.8265 21.941 9.30347 21.998C8.50011 22.0299 7.71168 21.7744 7.07974 21.2773C6.4478 20.7803 6.01373 20.0743 5.85547 19.286Z" fill="#909090"></path>
              </svg>
              </span><strong></strong>
              </button>
              {/* {hideEmoji&& <div className={`floating-icon ${showContainer ? '' : ''}`} onClick={() => toggleContainer()}>
        {location.pathname !== '/store' && storePlugins.length > 0 && (
        <div className='container '>
          <div className='header_sticky' style={{zIndex:'9999999'}}>

            <FontAwesomeIcon icon={faSquareCaretDown} size='2xl' style={{color:'#f08921'}}/>

          </div>
        </div>
        )}
      </div>} */}

      {showContainer && (
        <div className='container'>
          {location.pathname !== '/store' && storePlugins.length > 0 && (
            <div className='header_sticky_container'  style={{zIndex:'999999'}}>
              <div className='selected-plugin'>
              <FontAwesomeIcon icon={faCircleXmark} color='white' size='xl' onClick={closeContainer} style={{marginRight:'10px'}} />
                <span className='text-white'>{t('store-list.Selected-Packages')}: {storePlugins.length}</span>
                <button className='orange_btn m-1' onClick={() => redirecttoQueue()}>{t('store-list.buttons.Queue')}</button>
              </div>
            </div>
          )}
        </div>
      )}



              {!isModalOpen && showNotifications && (
        <div className="notifications-window" style={{position:'fixed',width:'420px', maxHeight:'335px'}}>
          <div className='notification_header' style={{position:'fixed',width:'419px',zIndex:'999999'}}>
              <h6>YOUR NOTIFICATION</h6>
              <button>{notifyList.length} New</button>
              <p>Clear All</p>
          </div>
          <div className='main'  style={{position:'relative',right:'60px',width:'100%', maxHeight: '275px', overflowY: 'auto', overflowX: 'hidden', padding: '0px', boxSizing: 'border-box' }}>
          {isToastOpen &&
          <div className="first_notification">
          <FontAwesomeIcon icon={faBell}/>
          <p>
            <span>
            {t ('subscription-message')} {subscription_status}
          <button className='orange_btn' style={{height:"30px", marginLeft:"10px", padding:"5px 15px", lineHeight: "1"}} onClick={() => setIsModalOpen(true)}> {t ('subscription-button')}</button>
            </span>
             {/* <span>
              <FontAwesomeIcon icon={faClock}/>
            <span style={{marginLeft:'8px', fontSize: '12px'}}></span>

            </span> */}
          </p>
      {/* <FontAwesomeIcon icon={faXmark}/> */}
        </div>
          }
          {notifyList.length > 0 &&
          notifyList.map((ik) => {
            return (
              <div className="first_notification">
          <FontAwesomeIcon icon={faBell}/>
          <p> {ik.message}
            <span>
              <FontAwesomeIcon icon={faClock}/>
            <span style={{marginLeft:'8px', fontSize: '12px'}}>{moment(ik.created_at).format('dddd, HH:mm:ss, YYYY MMMM')}</span>
            </span>
          </p>
      <FontAwesomeIcon icon={faXmark}/>
        </div>
            )
          })

        }
        </div>


        {/* <div className="first_notification">
          <FontAwesomeIcon icon={faBell}/>
          <p> Deserunt distinctio  ea assumendamolestiae necessitatibus, ratione libero ut ullam? Alias recusandae odio deleniti eligendi ratione.
            <span>
              <FontAwesomeIcon icon={faClock}/>
            <span style={{marginLeft:'8px', fontSize: '12px'}}>YESTERDAY</span>
            </span>
          </p>
      <FontAwesomeIcon icon={faXmark}/>
        </div>

        <div className="first_notification">
          <FontAwesomeIcon icon={faBell}/>
          <p> Deserunt distinctio  ea assumendamolestiae necessitatibus, ratione libero ut ullam? Alias recusandae odio deleniti eligendi ratione.
            <span>
              <FontAwesomeIcon icon={faClock}/>
            <span style={{marginLeft:'8px', fontSize: '12px'}}>3 DAYS</span>
            </span>
          </p>
      <FontAwesomeIcon icon={faXmark}/>
        </div> */}
        <div className='notification_footer' style={{position:'fixed',top:'345px',width:'419px'}}>
              <p>View All</p>
          </div>
        </div>
      )}

              {/* <button className="cblue_btn d-flex align-items-center me-2">
                        <span className="d-inline-block ms-1" onClick={handleShow}>Share Feedback</span>
                    </button> */}
              {/* <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Feedback Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3'>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control type='text' placeholder='Enter Subject' name='title' onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Form.Label>Description</Form.Label>
                      <textarea
                        className='form-control'
                        rows={7}
                        cols={50}
                        placeholder='Enter Description'
                        name='description'
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <ImageUploading multiple value={image} onChange={onChange} dataURLKey='data_url'>
                        {({ imageList, onImageUpload, onImageRemove }) => (
                          <>
                            <Button variant='secondary' onClick={onImageUpload}>
                              Upload Images
                            </Button>
                            {imageList.map((image, index) => (
                              <div className='content'>
                                <img
                                  src={image['data_url']}
                                  alt={'image-' + index}
                                  key={index}
                                  width='150px'
                                  style={{ margin: '10px' }}
                                />
                                <CloseButton onClick={() => onImageRemove(index)} />
                              </div>
                            ))}
                          </>
                        )}
                      </ImageUploading>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='primary' type='submit' onClick={handleSubmit}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal> */}
              {/* <button className="orange_btn d-flex align-items-center me-2">
                        <span className="d-inline-block ms-1" onClick={(e)=>navigate("/projects")}>Plugins ({userPlugins.length})</span>
                    </button>
                    <button className="cblue_btn d-flex align-items-center">
                        <span className="d-inline-block ms-1" onClick={(e)=>navigate("/reviewqueue")}>Queue</span>
                    </button>
                    <button className="orange_btn d-flex align-items-center ms-2">
                        <span className="d-inline-block ms-1">Clear queue</span>
                    </button> */}
              {/* <a className="btn orange_btn" id={`composer${project?.id}`} type="button" onClick={() => downloadComposer(project?.id,project?.name,project?.composer)}><FontAwesomeIcon icon={faDownload}/></a> */}
            </div>
          </div>
        </div>
      </div>
      <SubscriptionModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} subscription_status={subscription_status} children={undefined}  />
    </header>
  );
}

export default Header;
