import React, { useState } from 'react';
import { useForgotPassMutation } from '../features/api';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import fusepress_logo from '../img/fusepress_logo.png';

const ForgotPassword:React.FC = () => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [disableButton,setDisableButton] = useState<boolean>(false)

  const [Auth] = useForgotPassMutation();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setDisableButton(true)
    let data = {
      email: formData.email,
    };
    Auth(data)
      .then((res: any) => {
        setDisableButton(false)
        if (res.data.error === false) {
          toast.success(`${res.data.message}`);
        } else {
          toast.error(`${res.data.message}`);
        }
      })
      .catch((err: any) => {
        toast.error(`${err}`);
      });
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <main className='main'>
      <div className='main_auth_inner'>
        <div className='home_logo'>
          <img src={fusepress_logo} alt='fusepress_logo' />
        </div>
        <div className='auth'>
          <form onSubmit={handleSubmit}>
            <div className='input-container'>
              <FontAwesomeIcon icon={faEnvelope} className='fa fa-envelope icon' />
              <input
                type='email'
                className='input-field'
                placeholder='Email Id'
                name='email'
                onChange={handleChange}
                value={formData.email}
              />
            </div>
            <button className='btn_submit' disabled={disableButton}>{disableButton ? 'Loading...':'Submit'}</button>
            <div className='return_to_home'>
              <Link to='/auth' className='fp_return_home'>
                Return to login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}

export default ForgotPassword;
