/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Search from './Search';
import Type from './Type';
import Vendors from './Vendors';
import Ratings from './Ratings';
import logo from '../img/logo.jpg';
import userprofile from '../img/userprofile.svg';
import home from '../img/home.svg';
import favorites from '../img/favorites.svg';
import alarms from '../img/alarms.svg';
import settings from '../img/settings.svg';
import pen from '../img/pen.svg';
import clogo from '../img/FusePress_Icon.svg';
import clogotext from '../img/FusePress_Text.svg';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faBars, faCloudArrowUp, faHeart, faPlugCircleBolt, faQuestion, faSignOut, faSquarePlus, faStore, faXmark } from '@fortawesome/free-solid-svg-icons';

import { calculate, clearSearch, setDataType, setFlag, setMaxPrice, setMinPrice, setSearch } from '../features/storeFilters';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAllPluginQuery, useGetDeployQueueDataQuery,useGetQueueDataByUserQuery, useUserDetailsQuery } from '../features/api';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { fetchUserDetails } from '../features/userDetailsSlice';
import { useAppSelector } from '../features/hooks';
import axios from 'axios';
import { useDeleteQueryString, useUpdateQueryString } from '../utils/queryStringFunc';
const Sidenav:React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('token');
    // window.location.replace(process.env.REACT_APP_WORDPRESS_STAGING_URL + '/wp/wp-login.php?action=logout');
    // navigate('/auth');
    localStorage.setItem("syncLock","0")
    const logoutWindow = window.open(
      process.env.REACT_APP_WORDPRESS_STAGING_URL + '/wp/wp-login.php?action=logout',
      '_blank'
    );
    setTimeout(() => {
      if (logoutWindow) {
        logoutWindow.close();
        window.location.reload();
        navigate('/auth');
      }
    }, 2000);
  };
  const { data: ratingData } = useAllPluginQuery({ flag: 'ratings' });
  const { data: vendorData } = useAllPluginQuery({ flag: 'vendor' });
  const { data: typeData } = useAllPluginQuery({ flag: 'tag' });
  const { data: queueData } = useGetDeployQueueDataQuery({});
  const { data: queueDataByUser } = useGetQueueDataByUserQuery({});
  const  [enableIntro,setEnableIntro] = useState<any>(false)
  const deleteQueryString = useDeleteQueryString();
  const updateQueryString = useUpdateQueryString();
  console.log("queueData",queueData)
  const token = localStorage.getItem('token') || '';
  // useEffect(() => {
  //   dispatch(fetchUserDetails());


  // }, [dispatch]);

  const [collapsed, setCollapsed] = useState(false);
  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist');
    const fetchedWishlist = wishlistString ? JSON.parse(wishlistString) : [];
    setWishlist(fetchedWishlist);
  }, []);
  console.log('wishlist', wishlist);

  const handleToggle = () => {
    setCollapsed(!collapsed);
    const main:any = document.getElementsByTagName('main');
    const header:any = document.getElementsByClassName('header');
    if(collapsed){
      header[0].classList.remove('header--slid');
    }else{
      if (header.length > 0) {
        header[0].classList.add('header--slid');
      }
    }
    if(main.length > 0){
      if(!window.location.pathname.includes('/store') || window.location.pathname.includes('/store/queue')){
        if(collapsed){
          main[0].classList.remove('main--slide');
          main[0].style.marginLeft='60px';
        }else{
          main[0].classList.add('main--slide');
          main[0].style.marginLeft='200px';
        }
      }
    }

    // const computedWidth = window.getComputedStyle(header[0]).width;
    // const currentWidthValue = parseFloat(computedWidth);
    // const collapsedIcon  =document.getElementsByClassName('collapsedIcon');
    // const current = currentWidthValue-140;
    // const previous = currentWidthValue+140;

    // console.log('collapsed',collapsed,computedWidth);
    // if (header.length > 0) {
    //   if (collapsed === false) {
    //     // header[0].style.setProperty('width', `${current}px`, 'important');
    //     // const iconElement = collapsedIcon[0] as HTMLElement;
    //     // iconElement?.style.setProperty('left', '191px', 'important');
    //     console.log("Collapsed is false", {
    //       computedWidth: currentWidthValue,
    //       styleWidth: header[0].style.width,
    //       currentWidth: current,
    //       header,
    //       collapsed
    //     });
    //   } else {
    //     if (previous !== undefined ) {
    //       // header[0].style.setProperty('width', `${previous}px`, 'important');
    //       // const iconElement = collapsedIcon[0] as HTMLElement;
    //     // iconElement.style.setProperty('left', '51px', 'important');
    //       console.log("Collapsed is true", {
    //         styleWidth: header[0].style.width,
    //         previousWidth: previous
    //       });
    //     } else {
    //       console.error("Previous value is undefined or not a number", previous);
    //     }
    //   }
    // }

  };

  const handleToggleSidebar = ()=>{
    if(collapsed){
    const header:any = document.getElementsByClassName('header');
    if (header.length > 0) {
      header[0].classList.remove('header--slid');
    }
    // const computedWidth = window.getComputedStyle(header[0]).width;
    // const currentWidthValue = parseFloat(computedWidth);
    // const previous = currentWidthValue+140;
    // header[0].style.setProperty('width', `${previous}px`, 'important');
  }
    setCollapsed(false);
  }

  // const { data:userdetails } = useAppSelector((state:any) => state.userDetails);
  const {data:userdetails}= useUserDetailsQuery({})
  const loginToWordPress = async (): Promise<string | null> => {
    try {
      const formData = new FormData();
      formData.append('Email', userdetails.email);
      formData.append('token', token);
      // formData.append("Password",atob(data.password))
      const response = await axios.post(
        `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/signin-user/myaccount`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const redirectUrl = response.data;
      if (redirectUrl === 'Invalid login credentials.') {
        const email = userdetails.email;
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
          const emailBeforeAt = email.substring(0, atIndex);
          axios({
            url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
            method: 'GET',
            headers: {},
            params: {
              action: 'get_user_data',
              fname: userdetails.displayName,
              lname: userdetails.displayName,
              username: emailBeforeAt,
              email: userdetails.email,
              role: 'customer',
            },
            data: {},
          })
            .then((res1:any) => {
              console.log('res1', res1);
            })
            .catch((err1: any) => {
              console.log('err1', err1);
            });
        }
        return redirectUrl;
      } else {
        return redirectUrl;
      }
    } catch (error) {
      console.error('Error during login:', error);
      return null;
    }
  };
  useEffect(() => {
    if (typeof userdetails !== 'undefined') {
      const fetchLoginUrl = async () => {
        try {
          const url = (await loginToWordPress()) || '';
          localStorage.setItem('wordpressloginurl', url);
          setWordpressLoginUrl(url)

        } catch (error) {
          setWordpressLoginUrl('')
          console.error('Error fetching login URL:', error);
        }
      };

      fetchLoginUrl();
    }
  }, [userdetails]);
  const [wordpressLoginUrl, setWordpressLoginUrl] = useState<string>('');
  const setPluginType = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setDataType(event.target.value));
  };
  const setPluginFlag = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setFlag(event.target.value));
  };
  const setReset = ()=>{
    window.history.pushState({}, document.title, window.location.pathname);
    dispatch(calculate());
  }



  // function removeQueryString(key:any) {
  //   // Get the current URL
  //   const url = new URL((window as any).location);

  //   // Delete the query string parameter
  //   url.searchParams.delete(key);

  //   // Update the URL without reloading the page
  //   window.history.pushState({}, '', url);
  // }

  const enableIntroFunc = () => {
    // setEnableIntro(true)
    updateQueryString("intro","true")
  }

  console.log('vendorData', vendorData);
  const redirecttoAccounts = () => {
    console.log("hello",wordpressLoginUrl)
    if(wordpressLoginUrl.length > 0){
      window.open(wordpressLoginUrl,"_blank")
    }else {
      toast.error("something went wrong")
    }
  }
  const {t, i18n} = useTranslation()
  return (
    <aside  className='sidebar'>
      <div className={`second-aside ${collapsed ? 'sidebar--Collapse' : ' '}`}>
        <div className='main_logo'>
        <NavLink to='/'>
          <img src={clogo} alt='' />
          <img className='logo_text' src={clogotext} alt='' />
        </NavLink>
        </div>


        <div className='socond_menu' style={{marginTop:'40px'}}>
          <ul>
            <li >
              <button className='p-0' type='button' onClick={()=>redirecttoAccounts()} style={{background:'#101010', border:'none'}}>
                <img src={userprofile} alt='' title='Account' />
                <span className='menuText'>Account</span>

              </button>
            </li>
            <li >
  <Link to='/store' onClick={handleToggleSidebar}>
    <span className='menuImg' title='Store'><FontAwesomeIcon icon={faStore} style={{color:"gray"}} size='lg'/></span>
    <span className='menuText'>Store</span>
  </Link>
</li>

            {/* <li>
              <Link to='#'>
                <img src={favorites} alt='' />
              </Link>
            </li>
            <li>
              <Link to='#'>
                <img src={alarms} alt='' />
              </Link>
            </li>
            <li>
              <Link to='#'>
                <img src={settings} alt='' />
              </Link>
            </li> */}
            <li>
              <Link to='/user-packages' onClick={handleToggleSidebar}>
                {/* <img src={pen} alt='' /> */}
                <FontAwesomeIcon icon={faPlugCircleBolt} className='grey-color menuImg'  title='User Package'/>
                <span className='menuText'>User Package</span>
              </Link>
            </li>
            <li>
              <Link to='/deploy-pipeline' onClick={handleToggleSidebar}>
              {queueData?.length > 0 &&<div className='badge' style={{position:'absolute', backgroundColor:queueData?.length === 0 ? 'grey' : '#F08921', height:'18px', color:'white', width:'18px', top:'-8px', right:'-8px', borderRadius:'50%',padding:'0px', paddingTop:'2px'}}>{queueData?.length}</div>}
                {/* <img src={pen} alt='' /> */}
                <FontAwesomeIcon icon={faCloudArrowUp} className='grey-color' title='Deploy Pipeline'/>
                <span className='menuText'>Deploy Pipeline</span>

              </Link>
            </li>
            <li>
              <Link to='/store/queue' onClick={handleToggleSidebar}>
            {queueDataByUser?.length > 0 && <div className='badge' style={{position:'absolute', backgroundColor:queueDataByUser?.length === 0 ? 'grey' : '#F08921', height:'18px', color:'white', width:'18px', top:'-8px', right:'-11px', borderRadius:'50%',padding:'0px',paddingTop:'2px'}}>{queueDataByUser?.length}</div>}
                {/* <img src={pen} alt='' /> */}
                <FontAwesomeIcon icon={faSquarePlus} className='grey-color'  title='Queue'/>
                <span className='menuText'>Queue</span>

              </Link>
            </li>
            <li>
            <Link to='/wishlist' onClick={handleToggleSidebar}>
            {wishlist?.length >0 && <div className='badge' style={{position:'absolute', backgroundColor:wishlist?.length === 0 ? 'grey' : '#F08921', height:'18px', color:'white', width:'18px', top:'-8px', right:'-11px', borderRadius:'50%',padding:'0px', paddingTop:'2px'}}>{wishlist?.length}</div>}
                <FontAwesomeIcon icon={faHeart} className='grey-color' title='Wishlist'/>
                <span className='menuText'>Wishlist</span>

              </Link>
            </li>
            {/* <li onClick={() => enableIntroFunc()}>

                <FontAwesomeIcon icon={faQuestion} className='grey-color' title='Help'/>
                <span className='menuText' style={{marginLeft:"11px"}}>Help</span>


            </li> */}
            <li>
              <Link to='/' onClick={logout}>
                <FontAwesomeIcon icon={faSignOut} className='grey-color' title='Logout'/>
                <span className='menuText'>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`${collapsed ? 'collapseToggleIconOpen' : 'collapseToggleIcon'}`}
        onClick={handleToggle}
        style={{ marginTop: '3px',position:'fixed',top:'250px',zIndex:'9999999', cursor:'pointer' }}
      >
        <FontAwesomeIcon
          icon={collapsed ? faAnglesLeft : faAnglesRight}
          color='white'
        />
      </div>
      {location.pathname === '/store' && (
        <div className={`first-aside  ${collapsed ? 'first--Collapse' : ' '}`}>
          {/* <div className='logo px-3 text-center'>
            <NavLink to='/' onClick={handleToggleSidebar}>
              <img src={logo} alt='' />
            </NavLink>
          </div> */}
          <div className="first-aside-inner">
          <Search />
          <div className='accordion accordion-flush' id='sideaccordion'>
            {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingCategory">
                            <button className="accordion-button px-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCategory" aria-expanded="false" aria-controls="flush-collapseCategory">
                                Category
                            </button>
                        </h2>
                        <div id="flush-collapseCategory" className="accordion-collapse px-4 collapse" aria-labelledby="flush-headingCategory" data-bs-parent="#sideaccordion">
                            <div className="accordion-body">
                                <ul>
                                    <li><Link to="#"><span>E-Commerce</span><span>29</span></Link></li>
                                    <li><Link to="#"><span>SEO</span><span>11</span></Link></li>
                                    <li><Link to="#"><span>Add ones</span><span>205</span></Link></li>
                                    <li><Link to="#"><span>Social Networking</span><span>866</span></Link></li>
                                    <li><Link to="#"><span>Membership</span><span>2180</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='flush-headingTags'>
                <button
                  className='accordion-button px-4 collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapseTags'
                  aria-expanded='false'
                  aria-controls='flush-collapseTags'
                >
                   {t('store-list.sidebar.Tags')}
                </button>
              </h2>
              <div
                id='flush-collapseTags'
                className='accordion-collapse px-4 collapse'
                aria-labelledby='flush-headingTags'
                data-bs-parent='#sideaccordion'
              >
                <div className='accordion-body'>
                  <Type typeData={typeData} />
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='flush-headingVendor'>
                <button
                  className='accordion-button px-4 collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapseVendor'
                  aria-expanded='false'
                  aria-controls='flush-collapseVendor'
                >
                   {t('store-list.sidebar.Vendor')}
                </button>
              </h2>
              <div
                id='flush-collapseVendor'
                className='accordion-collapse px-4 collapse'
                aria-labelledby='flush-headingVendor'
                data-bs-parent='#sideaccordion'
              >
                <div className='accordion-body'>
                  <Vendors vendorData={vendorData} />
                </div>
              </div>
            </div>
            {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingPrice">
                            <button className="accordion-button px-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePrice" aria-expanded="false" aria-controls="flush-collapsePrice">
                                Price
                            </button>
                        </h2>
                        <div id="flush-collapsePrice" className="accordion-collapse px-4 collapse" aria-labelledby="flush-headingPrice" data-bs-parent="#sideaccordion">
                            <div className="accordion-body">
                                <div className="pricebox">

                                    <div className="mintext">
                                        <span>$</span>
                                        <input name="minPrice" type="text" onChange={(e)=>{dispatch(setMinPrice(e.target.value))}}/>
                                    </div>
                                    <div className="price_divider">-</div>
                                    <div className="maxtext">
                                        <span>$</span>
                                        <input name="maxPrice" type="text" onChange={(e)=>{dispatch(setMaxPrice(e.target.value))}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='flush-headingRatings'>
                <button
                  className='accordion-button px-4 collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapseRatings'
                  aria-expanded='false'
                  aria-controls='flush-collapseRatings'
                >
                   {t('store-list.sidebar.Ratings')}
                </button>
              </h2>
              <div
                id='flush-collapseRatings'
                className='accordion-collapse px-4 collapse'
                aria-labelledby='flush-headingRatings'
                data-bs-parent='#sideaccordion'
              >
                <div className='accordion-body'>
                  <Ratings ratingData={ratingData} />
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='flush-headingPlugins'>
                <button
                  className='accordion-button px-4 collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapsePlugins'
                  aria-expanded='false'
                  aria-controls='flush-collapsePlugins'
                >
                   {t('store-list.sidebar.Plugin')}
                </button>
              </h2>
              <div
                id='flush-collapsePlugins'
                className='accordion-collapse px-4 collapse'
                aria-labelledby='flush-headingPlugins'
                data-bs-parent='#sideaccordion'
              >
                <div className='accordion-body'>
                  <div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        // id="checkbox"
                        name='plugintype'
                        value='private'
                        onChange={e => setPluginType(e)}
                      />
                      <label className='form-check-label text-secondary' htmlFor='checkbox'>
                      {t('store-list.sidebar.Private-Plugin')}
                      </label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        // id="checkbox"
                        name='plugintype'
                        value='public'
                        onChange={e => setPluginType(e)}
                      />
                      <label className='form-check-label text-secondary' htmlFor='checkbox'>
                      {t('store-list.sidebar.Public-Plugin')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='flush-headingPlugins1'>
                <button
                  className='accordion-button px-4 collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapsePlugins1'
                  aria-expanded='false'
                  aria-controls='flush-collapsePlugins1'
                >
                   {t('store-list.sidebar.Type')}
                </button>
              </h2>
              <div
                id='flush-collapsePlugins1'
                className='accordion-collapse px-4 collapse'
                aria-labelledby='flush-headingPlugins1'
                data-bs-parent='#sideaccordion'
              >
                <div className='accordion-body'>
                  <div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        // id="checkbox"
                        name='plugintype1'
                        value='plugins'
                        onChange={e => setPluginFlag(e)}
                      />
                      <label className='form-check-label text-secondary' htmlFor='checkbox'>
                      {t('store-list.sidebar.Plugins')}
                      </label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        // id="checkbox"
                        name='plugintype1'
                        value='theme'
                        onChange={e => setPluginFlag(e)}
                      />
                      <label className='form-check-label text-secondary' htmlFor='checkbox'>
                      {t('store-list.sidebar.Theme')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{marginTop:'20px', marginLeft:'10px'}}>
                  <button className='orange_btn' onClick={setReset}>
                    Reset Filter
                  </button>
                </div>
          </div>
          </div>
        </div>
      )}
    </aside>
  );
}
export default Sidenav;
