/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import UserPackagesCard from './UserPackagesCard';
import { useGetUserWisePluginQuery } from '../features/api';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';

const UserPackages:React.FC = () => {
  const [userPlugin, setUserPlugin] = useState<Array<any>>([]);

  const { data: userData, isLoading } = useGetUserWisePluginQuery({});
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof userData !== 'undefined') {
      setUserPlugin(userData);
    }
  }, [userData]);

  const onNavigatetoAddPackage = () => {
    navigate('/add-user-package');
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className='main main-side-sort overflow-hidden'>
      <div className='col-md-2 mx-4'>
        <button className='orange_btn' type='button' onClick={() => onNavigatetoAddPackage()}>
          Submit new plugin
        </button>
      </div>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>Item name</th>
              <th>Upload date</th>
              <th>Version</th>
              <th>Vendor Name</th>
              <th>Times used</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
              {Array.isArray(userPlugin) && userPlugin.length > 0 ? (
              userPlugin.map((i: any) => {
                if (i.approval_status === 'pending') {
                  return <UserPackagesCard key={i.id} item={i} />;
                }
              })
            ) : (
              <tr>
                <td colSpan={6}>No plugins available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </main>
  );
}
export default UserPackages;
